import { PartsApiFp } from "@mr_rse/rse-api";
import axios from "axios";
const api = PartsApiFp();
const axiosInstance = axios.create({
  auth: {
    username: "Benutzer_MR_RV",
    password: "aaYwHpJ3aJ",
  },
  headers: {
    Accept: "application/json",
  },
});
const loadCorpus = (baseURL: string, id: string) => {
  return (
    api
      .getCompositePartById(`urn:mr:partid:comp:${id}`)
      // @ts-ignore
      .then((fn) => fn(axiosInstance, baseURL))
      .then(({ data }) => data)
  );
};
export default loadCorpus;
