import {
  CameraControllerReturn,
  CameraControlPanelProps,
} from "@motionray/viewer/dist/components/camera/CameraController";
import { CameraContext } from "@motionray/viewer/dist/context/CameraContext";
import { useCallback, useContext, useEffect } from "react";

type ValidCameraPlacementNames = keyof CameraControllerReturn;

const map: Record<string, ValidCameraPlacementNames> = {
  "Von Links": "toLeft",
  "Von Rechts": "toRight",
  "Von Schräg Oben": "toIso",
  "Von Vorne": "toFront",
  "Nur Zoom": "zoomToFit",
};
const CameraPanel: React.FunctionComponent<CameraControlPanelProps> = () => {
  const { handler } = useContext(CameraContext);
  const onClick = useCallback(
    (fnName: ValidCameraPlacementNames) => {
      console.log(handler);
      console.log(fnName);
      handler?.[fnName]();
    },
    [handler],
  );
  useEffect(() => {
    console.log("handler received", handler);
  }, [handler]);
  console.log(handler);
  return (
    <div>
      {Object.entries(map).map(([name, fnName]) => (
        <button key={name + "_btn"} onClick={() => onClick(fnName)}>
          {name}
        </button>
      ))}
    </div>
  );
};
export default CameraPanel;
