import './App.css';
import Editor from './Editor';

function App() {
  return (
    <Editor />
  );
}

export default App;
