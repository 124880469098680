import React, { useRef } from "react";

import Viewer from "@motionray/viewer/dist/components/Viewer";
import { CompositePart, BoundingBox3D } from "@mr_rse/rse-api";
import loadCorpus from "./api";
import CameraPanel from "./CameraPanel";
import Tree from "react-d3-tree";
import { UrnProvider, useUrnResolver } from "@motionray/urnresolver";
import ExtraWrapper from "./ExtraWrapper";
const baseURL_ =
  window.localStorage.getItem("baseURL") ?? "https://rse.staging.cdn3d.com";
const corpusId =
  window.localStorage.getItem("corpusId") ??
  "MR72942b6e4bd04ca35e15393b38d0cda465eaee0d0a3d7532039707:base";

const EditorWithClient: React.FC = () => {
  const [root, setRoot] = React.useState<CompositePart | null>(null);
  const [showTree, setShowTree] = React.useState(false);
  const toggleTree = React.useCallback(
    () => setShowTree((old) => !old),
    [setShowTree],
  );
  const [bb, setBB] = React.useState<BoundingBox3D | null>(null);
  const [lastUpdate, setLastUpdate] = React.useState<number>(+new Date());
  const [cid, setCid] = React.useState<string>(corpusId);
  const [baseURL, setBaseURL] = React.useState<string>(baseURL_);
  const onLoad = React.useCallback(() => {
    loadCorpus(baseURL, cid).then((root) => {
      setRoot(root);
      setBB(root.boundingBox);
    });
  }, [baseURL, cid]);
  const orgChart = React.useMemo(
    () => (!root ? {} : getTreeData(root)),
    [root],
  );
  const [isMini, setIsMini] = React.useState(false);
  const sceneHandleRef = useRef(null);
  return (
    <div style={{ display: "flex", height: "100%" }}>
      <div
        id="placeholder"
        style={{ width: "450px", backgroundColor: "lightgray" }}
      >
        <h1>Bookmarklets(!)</h1>
        <a href="javascript:(function()%7Bwindow.localStorage.setItem(%22mr.viewer.debug%22%2C%20%22false%22)%7D)()">
          Disable debug
        </a>
        <br />
        <a href="javascript:(function()%7Bwindow.localStorage.setItem(%22mr.viewer.debug%22%2C%20%22true%22)%7D)()">
          Enable debug
        </a>
        <h1>Operations</h1>
        <button
          onClick={React.useCallback(() => {
            setLastUpdate(+new Date());
            onLoad();
          }, [onLoad])}
        >
          Trigger update
        </button>
        <br />
        <button onClick={toggleTree}>Show tree</button>
      </div>
      <div className="editor">
        <div className="viewer">
          {root && bb && (
            <UrnProvider
              rseUrl={"https://rse.staging.cdn3d.com"}
              uiElementsUrl={"https://....."}
              assetsUrl={"https://...."}
            >
              <ExtraWrapper
                boundingBox={bb}
                root={root}
                // mtlLibURL={"http://localhost:3000/default.mtl"}
                CameraControl={CameraPanel}
                transparencyGroups={{ default: 0.5, external: 0 }}
                ref={sceneHandleRef}
                canSelect={true}
                onSelect={console.log}
              />
            </UrnProvider>
          )}
        </div>
        <div className="helper">
          <input
            type="text"
            value={baseURL}
            onChange={(e) => {
              console.log(e.currentTarget.value);
              setBaseURL(e.currentTarget.value.trim());
              return;
            }}
            style={{ width: "300px" }}
          />
          <input
            type="text"
            value={cid}
            onChange={(e) => setCid(e.currentTarget.value.trim())}
            style={{ width: "500px" }}
          />
          <button onClick={onLoad}>Load</button>
          <br />
          <p>Mini Mode: {isMini ? "on" : "off"}</p>
          <br />
          <button
            onClick={() => {
              if (!sceneHandleRef.current) {
                return;
              }
              (
                sceneHandleRef.current as { collada: (name: string) => void }
              ).collada(root?.name ?? "root");
            }}
          >
            XPort
          </button>
        </div>
      </div>
      <div
        id="treeWrapper"
        style={{
          display: showTree ? "block" : "none",
          width: "100%",
          height: "100%",
          position: "absolute",
          top: "0px",
          left: "450px",
          backgroundColor: "lightgray",
        }}
      >
        {/* @ts-ignore */}
        <Tree
          /* @ts-ignore */
          data={orgChart}
          pathFunc={"step"}
          orientation={"horizontal"}
          separation={{ siblings: 1, nonSiblings: 5 }}
        />
      </div>
    </div>
  );
};

const Editor: React.FC = () => {
  return <EditorWithClient />;
};
type TreeItem = {
  name: string;
  children: TreeItem[];
  attributes?: Record<string, string>;
};
const getTreeData = (
  { assembly, name, tags, manifest3D, hash }: CompositePart,
  hashChain: string[] = [],
): TreeItem => {
  const children = (assembly ?? []).map((assembly) => {
    if (!assembly.part) {
      return undefined;
    }
    return getTreeData(assembly.part, hashChain.concat(hash));
  });

  const attributes: TreeItem["attributes"] = {
    hash,
  };
  if (tags.length > 0) {
    attributes.tags = tags.join(", ");
  }
  if (manifest3D && assembly.length === 0) {
    attributes.manifest = manifest3D;
    attributes.hashChain = hashChain.concat([hash]).join(", ");
  }

  return {
    name:
      name.length > 30
        ? name.substring(0, 15) + "..." + name.substring(name.length - 15, 15)
        : name,
    children: children.filter((v) => !!v) as TreeItem[],
    attributes,
  };
};

export default Editor;
