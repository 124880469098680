import { useUrnResolver } from "@motionray/urnresolver";
import Viewer, {
  SceneExportHandle,
} from "@motionray/viewer/dist/components/Viewer";
import { ViewerProps } from "@motionray/viewer/dist/components/Viewer";
import React, { forwardRef } from "react";

const ExtraWrapper = forwardRef<
  SceneExportHandle,
  Omit<ViewerProps, "urnResolver">
>(({ boundingBox, root, CameraControl }, fref) => {
  const urnResolver = useUrnResolver();

  // @ts-expect-error FIXME
  window.urnresolver = urnResolver;
  return (
    <Viewer
      boundingBox={boundingBox}
      root={root}
      // mtlLibURL={"http://localhost:3000/default.mtl"}
      urnResolver={urnResolver}
      CameraControl={CameraControl}
      transparencyGroups={{ default: 0.5, external: 0 }}
      ref={fref}
      canSelect={true}
      onSelect={console.log}
    />
  );
});

export default ExtraWrapper;
